<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header>
            <h1 class="title">Desculpe!</h1>
          </md-card-header>
          <md-card-content>
            <div class="alert alert-warning">
              <h2>
                <md-icon class="md-size-3x">warning</md-icon>
                - Você não esta autorizado para usar este recurso!
              </h2>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {}
};
</script>
<style lang="scss" scoped></style>
